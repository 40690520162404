var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("custom-data-table", {
        ref: "datatable-schedule",
        attrs: {
          "query-node": "schedules",
          "query-context": "Maintenance",
          fields: _vm.fields,
          filters: _vm.filters,
          query: _vm.toScheduleQuery,
          "fixed-filters": _vm.fixed_filters,
        },
        scopedSlots: _vm._u([
          {
            key: "driverName",
            fn: function (props) {
              return _c("span", {}, [
                _vm._v(
                  " " +
                    _vm._s(
                      props.rowData.driver.name ||
                        _vm.$t("maintenance.labels.driverName")
                    ) +
                    " "
                ),
              ])
            },
          },
          {
            key: "car",
            fn: function (props) {
              return _c("span", {}, [
                _vm._v(
                  " " +
                    _vm._s(
                      props.rowData.car.license_place ||
                        _vm.$t("maintenance.labels.carLicensePlate")
                    ) +
                    " "
                ),
              ])
            },
          },
          {
            key: "odometer",
            fn: function (props) {
              return _c("span", {}, [
                _vm._v(
                  " " +
                    _vm._s(props.rowData.metadata.odometer_at_TO_SCHEDULE) +
                    " "
                ),
              ])
            },
          },
          {
            key: "make_schedule",
            fn: function (props) {
              return [
                _c(
                  "b-btn",
                  {
                    attrs: { variant: "primary" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.rowClicked(props.rowData)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-calendar-plus-o" }),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("maintenance.texts.doMaintenanceSchedule")
                        ) +
                        " "
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
      _c("modal-create-schedule", {
        attrs: {
          driver: _vm.schedule.driver || {},
          booking: _vm.schedule.booking || {},
          schedule: _vm.schedule || {},
          car: _vm.schedule.car || {},
        },
        on: { scheduled: _vm.onRefreshDatatable },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }