var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Layout",
    [
      _c("kovi-page-title", {
        attrs: { text: _vm.$t("maintenance.texts.maintenanceSchedule") },
      }),
      _c(
        "b-row",
        [_c("b-col", { attrs: { sm: "12" } }, [_c("schedule-list")], 1)],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }