<template>
  <div>
    <custom-data-table
      ref="datatable-schedule"
      query-node="schedules"
      query-context="Maintenance"
      :fields="fields"
      :filters="filters"
      :query="toScheduleQuery"
      :fixed-filters="fixed_filters"
    >
      <span slot="driverName" slot-scope="props">
        {{ props.rowData.driver.name || $t('maintenance.labels.driverName') }}
      </span>

      <span slot="car" slot-scope="props">
        {{ props.rowData.car.license_place || $t('maintenance.labels.carLicensePlate') }}
      </span>

      <span slot="odometer" slot-scope="props">
        {{ props.rowData.metadata.odometer_at_TO_SCHEDULE }}
      </span>

      <template slot="make_schedule" slot-scope="props">
        <b-btn variant="primary" @click.prevent="rowClicked(props.rowData)">
          <i class="fa fa-calendar-plus-o" />
          {{ $t('maintenance.texts.doMaintenanceSchedule') }}
        </b-btn>
      </template>
    </custom-data-table>

    <modal-create-schedule
      :driver="schedule.driver || {}"
      :booking="schedule.booking || {}"
      :schedule="schedule || {}"
      :car="schedule.car || {}"
      @scheduled="onRefreshDatatable"
    />
  </div>
</template>

<script>
import CustomDataTable from '@components/CustomDataTable';
import TO_SCHEDULE_LIST from '@graphql/schedules/queries/list-to-schedule.gql';
import ModalCreateSchedule from '@components/modals/create-schedule';
import ScheduleStatusEnum from '@graphql/schedules/enum/status';

export default {
  name: 'ScheduleList',
  components: {
    CustomDataTable,
    ModalCreateSchedule,
  },
  data() {
    return {
      schedule: {},
      toScheduleQuery: TO_SCHEDULE_LIST,
      fields: [
        {
          name: 'driver.name',
          title: this.$t('maintenance.fields.driverName'),
          sortField: 'driverName',
        },
        {
          name: 'car.license_plate',
          title: this.$t('maintenance.fields.car'),
          sortField: 'car',
        },
        {
          name: 'metadata.odometer_at_TO_SCHEDULE',
          title: this.$t('maintenance.fields.odometer'),
        },
        {
          name: 'make_schedule',
          title: this.$t('maintenance.fields.makeSchedule'),
          class: 'calendar',
        },
      ]
    };
  },
  computed: {
    filters() {
      return {
        query: {
          type: 'textfield',
          props: {
            label: false,
            placeholder: this.$t('maintenance.labels.searchPlaceholder'),
          },
          valueParserType: String,
          graphqlQuery(value) {
            return {
              search: {
                field: 'driver_name',
                value,
              },
            };
          },
        },
      };
    },
    fixed_filters() {
      return {
        status: ScheduleStatusEnum.TO_SCHEDULE,
      };
    },
  },
  methods: {
    onRefreshDatatable() {
      this.$refs['datatable-schedule'].$_refresh();
    },
    rowClicked(item) {
      this.schedule = item;
      this.$root.$emit('bv::show::modal', 'modal-create-schedule');
    },
  },
};
</script>
